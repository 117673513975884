export default {
    menuAside: {
        menu: 'Menu',
        dashboard: 'Dashboard',
        rollCalls: 'Roll Calls',
        rollCallsActive: 'Active',
        rollCallsHistory: 'History',
        clients: 'Clients',
        clientsList: 'Clients list',
        credits: 'Credits',
        accounts: 'Accounts',
        products: 'Products',
        list: 'List',
        categories: 'Categories',
        manufacture: 'Manufacture',
        incomes: 'Incomes',
        transfers: 'Transfers',
        expenditures: 'Expenditures',
        currencyAccounts: 'Accounts',
        warehouse: 'Warehouse',
        warehouseIncomes: 'Incomes',
        warehouseExpenditures: 'Expenditures',
        logout: 'Log out',
    },
    menuNavBar: {
        myprofile: 'My Profile',
        logout: 'Log out',
        lightdark: 'Light/Dark',
    },
    somethingWrong: 'Something wrong',
    paginator: 'Page {currentPageHuman} of {numPages}',
    cancel: 'Cancel',
    apply: 'Apply',
    reset: 'Reset',
    selectoption: 'Select one',
    selectLabel: 'Press enter to select',
    deselectLabel: 'Press enter to remove',
    selectedLabel: 'Selected',
    "Nothing's here…": "Nothing's here…",
    'List is empty': 'List is empty',
    'No elements found': 'No elements found. Consider changing the search query',
    profile: {
        title: 'Profile',
        avatar: 'Avatar',
        avatarHelp: 'Max 500kb',
        upload: 'Upload',
        firstname: 'First name',
        firstnameHelp: 'Required. Your first name',
        lastname: 'Last name',
        lastnameHelp: 'Required. Your last name',
        phone: 'Phone',
        phoneHelp: 'Your phone',
        submitLabel: 'Submit',
        timezone: 'Current timezone',
        timezoneHelp: 'Choose your timezone',
        language: 'Current language',
        languageHelp: 'Choose your language',
        currentPassword: 'Current password',
        currentPasswordHelp: 'Required. Your current password',
        newPassword: 'New password',
        newPasswordHelp: 'Required. New password',
        confirmNewPassword: 'Confirm password',
        confirmNewPasswordHelp: 'Required. New password one more time',
        usercardHello: 'Hello'
    },
    products: {
        newproduct: 'New product',
        add: 'Add',
        category: 'Category',
        product: 'Product',
        categoryHelp: 'Please choose category',
        name: 'Name',
        nameHelp: 'Please enter product name',
        productlist: 'Products list',
        addcategory: 'New product category',
        categoryNameHelp: 'Please enter product category name',
        categoryList: 'Products categories list',
        parentcategory: 'Parent category',
        price: 'Price',
        apply: 'Apply',
        "There are not enough products in stock": "There are not enough products in stock"
    },
    manufacture: {
        carAmount: 'Car amount',
        user: 'User',
        timestamp: 'Timestamp',
        viewItems: 'View items',
        itemName: 'Item name',
        amount: 'Amount',
        newCarIncome: 'New car income',
        add: 'Add',
        carConfiguration: 'Car configuration',
        carConfigurationHelp: 'Please choose configuration',
        amountHelp: 'Please enter amount',
        itemsInIncomeTitle: 'Items in income',
        incomes: 'Incomes',
        addIncome: 'Add income',
        expenditures: 'Expenditures',
        expenditure: 'Expenditure',
    },
    clients: {
        newClient: 'New client',
        add: 'Add',
        firstName: 'First name',
        firstNameHelp: 'Please enter first name',
        lastName: 'Last name',
        lastNameHelp: 'Please enter last name',
        phone: 'Phone',
        phoneHelp: 'Please enter phone number',
        clientsTitle: 'Clients',
        addClient: 'Add client',
        fullname: 'Name',
        company: 'Company',
        spent: 'Spent',
        newClientCurrencyAccount: 'New client currency account',
        client: 'Client',
        clientHelp: 'Please choose client',
        currency: 'Currency',
        currencyHelp: 'Please choose currency',
        clientsCreditsTitle: 'Clients credits',
        duplicateCCAError: 'Duplicate client currency account with this currency',
        newClientCreditLine: 'New client credit line',
        creditLineAmount: 'Amount',
        creditLineAmountHelp: 'Please enter credit line amount',
        clientsCreditLinesTitle: 'Clients credit lines',
        addLine: 'Add line',
        open: 'Open',
        isClose: 'Is close',
        isOpen: 'Is open',
        clientName: 'Client Name',
        credits: 'Credits',
        credit: 'Credit',
        debt: 'Debt',
        loans: 'Loans',
        clientsCurrencyAccounts: 'Clients currency accounts',
        addAccount: 'Add account',
        balanceIsNotEnough: 'The balance is not enough',
        newMoneyTransfer: 'New money transfer',
        newMoneyIncome: 'New money income',
        newMoneyExpenditure: 'New money expenditure',
        debitAmount: 'Debit amount',
        debitAmountHelp: 'Please enter debit amount',
        receiptCurrencyAccount: 'Receipt currency account',
        receiptCurrencyAccountHelp: 'Please choose receipt currency account',
        balance: 'Balance',
        amount: 'Amount',
        amountHelp: 'Please enter amount',
        receiptAmount: 'Receipt amount',
        receiptAmountHelp: 'Please enter receipt amount',
        clientCurrencyAccountTitle: 'Client currency account',
        moneyTransfers: 'Money Transfers',
        moneyIncomes: 'Money Incomes',
        moneyExpenditures: 'Money Expenditures',
        receipt: 'Receipt',
        debit: 'Debit',
        timestamp: 'Timestamp',
        user: 'User',
        debitor: 'Debitor',
        recipient: 'Recipient',
        opponent: 'Debitor/Recipient',
        newCreditRepayment: 'New credit repayment',
        currencyAccount: 'Сurrency Account',
        "Please choose currency account in client currency": 'Please choose currency account in client currency',
        "Please enter repayment amount": "Please enter repayment amount",
        "Exchange rate": "Exchange rate",
        exchangeRateHelp: "Please enter exchange rate. Amount to repayment",
        "Client credit line": "Client credit line",
        "in": "in",
        addRepayment: 'Add repayment',
        "Credit line is closed": "Credit line is closed",
        "Credit line amount is not enogh": "Credit line amount is not enogh",
        "ClientDoesnotExists": "Client doesn't exists"
    },
    cart: {
        addToCart: "Add to cart",
        price: "Price",
        amount: "Amount",
        total: "Total",
        ok: "Ok",
        removeFromCart: "Remove",
        add: 'Add',
        view: 'View',
        cartTitle: 'Cart',
        name: 'Name',
        clearCart: 'Clear',
        clearCartTitle: 'Clear cart',
        clearCartBody: 'Do you want to clear the cart?',
        pay: 'Pay',
        kassa: 'Cash',
        credit: 'Credit',
        prepayment: 'Prepayment',
        paymentType: 'Payment type',
        "ProductsDoesNotExists": "Products doesn't exists"
    },
    currencyAccounts: {
        account: 'Currency account',
        "CurrencyAccounDoesnotExists": "CurrencyAccount doesn't exists",
        title: 'Currency accounts',
        name: 'Name',
        currency: 'Currency',
        balance: 'Balance',
        view: 'View',
        newCurrencyAccount: 'New currency account',
        add: 'Add',
        currencyHelp: 'Please choose currency',
        moneyTransfers: 'Money Transfers',
        moneyIncomes: 'Money Incomes',
        moneyExpenditures: 'Money Expenditures',
        receipt: 'Receipt',
        debit: 'Debit',
        timestamp: 'Timestamp',
        user: 'User',
        debitor: 'Debitor',
        recipient: 'Recipient',
        opponent: 'Debitor/Recipient',
        addAccount: 'Add account',
        balanceIsNotEnough: 'The balance is not enough',
        newMoneyTransfer: 'New money transfer',
        newMoneyIncome: 'New money income',
        newMoneyExpenditure: 'New money expenditure',
        debitAmount: 'Debit amount',
        debitAmountHelp: 'Please enter debit amount',
        receiptCurrencyAccount: 'Receipt currency account',
        receiptCurrencyAccountHelp: 'Please choose receipt currency account',
        amount: 'Amount',
        amountHelp: 'Please enter amount',
        receiptAmount: 'Receipt amount',
        receiptAmountHelp: 'Please enter receipt amount',
        transfer: "Transfer",
        income: "Income",
        expenditure: "Expenditure",
        transfers: 'Transfers',
        incomes: 'Incomes',
        expenditures: 'Expenditures',

    },
    warehouse: {
        productlist: 'Product list in warehouse',
        productIncomelist: 'Product Incomes list',
        productExpenditurelist: 'Product Expenditures list',
        receivingUser: 'Receiving user',
        submittingUser: 'Submitting user'
    },
    rollCalls: {
        EnterMessage: 'Enter message',
        ok: "Ok",
        start: "Start",
        expires: "Expires",
        tooFarFromTheOfficeError: ' You are too far from the office',
        question: 'Question',
        answerState: 'Answer',
        timestamp: 'Timestamp',
        history: 'History',
        locationRequired: 'Location required'
    }
}