import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { useUserStore } from '@/stores/user_store.js'

import router from '@/router'

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;
// const vapidApiKey = `${import.meta.env.VITE_VAPID_PUBLIC_KEY}`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        // user: JSON.parse(localStorage.getItem('user')),
        authToken: JSON.parse(localStorage.getItem('authToken')),
        returnUrl: null,
        isTelegramApp: false,
        vapidPubKey: `${import.meta.env.VITE_VAPID_PUBLIC_KEY}`
    }),
    actions: {
        async login(username, password) {
            const authToken = await fetchWrapper.post(`${baseUrl}/auth/token/`, { username, password });
            // const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });

            // update pinia state
            // this.user = user;
            this.authToken = authToken;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('authToken', JSON.stringify(authToken));
            await this.testToken()
            // await this.getVapidPubKey()
            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/');
        },
        async tglogin(qstring) {
            return new Promise((resolve, reject) => {
                fetchWrapper.post(`${baseUrl}/auth/telegram-token/`, { qstring }).then(async (authToken) => {
                    this.authToken = authToken
                    localStorage.setItem('authToken', JSON.stringify(authToken));
                    await this.testToken()
                    router.push(this.returnUrl || '/');
                    resolve('ok')
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        },
        logout() {
            // this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('authToken');
            router.push('/login');
        },
        async testToken() {
            const userStore = useUserStore()
            if (this.authToken === null) {
                const returnUrl = window.location.pathname + window.location.search + window.location.hash
                this.returnUrl = returnUrl !== '/login' ? returnUrl : '/'
                router.push('/login')
            }
            let user;
            const that = this
            await fetchWrapper.get(`${baseUrl}/auth/users/me/`).then(async (user) => {
                if (!that.isTelegramApp) {
                    // that.getVapidPubKey()
                    window.addEventListener('load', function () {
                        // Do everything if the Browser Supports Service Worker
                        if ('serviceWorker' in navigator) {
                            const serviceWorker = document.querySelector('meta[name="service-worker-js"]').content
                            navigator.serviceWorker.register(serviceWorker).then(function (reg) {
                                initialiseState(reg)
                            })
                        }

                        // Once the service worker is registered set the initial state
                        function initialiseState(reg) {
                            // Are Notifications supported in the service worker?
                            if (!reg.showNotification) {
                                // Show a message and activate the button
                                alert('Showing notifications are not supported in your browser.')
                                return
                            }

                            // Check the current Notification permission.
                            // If its denied, it's a permanent block until the
                            // user changes the permission
                            if (Notification.permission === 'denied') {
                                // Show a message and activate the button
                                alert('Push notifications are blocked by your browser.')
                                return
                            }

                            // Check if push messaging is supported
                            if (!('PushManager' in window)) {
                                // Show a message and activate the button
                                alert('Push notifications are not available in your browser.')
                                return
                            }
                            that.subscribeNotify(reg)
                            // We need to get subscription state for push notifications and send the information to server
                            // reg.pushManager.getSubscription().then((subscription) => {
                            //   if (subscription) {
                            //     auth.sendPushInfo(subscription, function (response) {
                            //       // Check the information is saved successfully into server
                            //       if (response.status === 201) {
                            //         // Show unsubscribe button instead
                            //         alert('Successfully subscribed to push notifications.')
                            //       }
                            //     })
                            //   }
                            // })
                        }
                    })
                }
                userStore.setUser(user)
            }).catch(async (error) => {
                console.log('error', error);
                const result = await this.tryRefreshToken()
                if (result) {
                    user = await fetchWrapper.get(`${baseUrl}/auth/users/me/`).catch(async () => {
                        router.push('/login')
                    })
                } else {
                    router.push('/login')
                }
            });
            return user
        },
        async tryRefreshToken() {
            if (this.authToken === null) {
                router.push('/login')
                return false
            }
            const refresh = this.authToken.refresh;
            const { access } = await fetchWrapper.post(`${baseUrl}/auth/token/refresh/`, { refresh }).catch(() => {
                this.logout()
                router.push('/login')
                return false
            }
            );
            localStorage.setItem('authToken', JSON.stringify({ refresh, access }));
            this.authToken.access = access
            return true
        },
        async register(username, password) {
            return new Promise((resolve, reject) => {
                fetchWrapper.post(`${baseUrl}/auth/users/`, { username, password }).then(() => {
                    resolve('ok')
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        },
        async getVapidPubKey() {
            return new Promise((resolve, reject) => {
                fetchWrapper.get(`${baseUrl}/webpush/subscription-information/pubkey/`).then(async (pubkey) => {
                    this.vapidPubKey = pubkey.key
                    resolve(this.vapidPubKey)
                }).catch(async () => {
                    alert('Notification vapid key not available')
                });
            }
            )
        },
        async sendPushInfo(subscription) {
            var browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase()
            var user_agent = navigator.userAgent
            subscription = subscription.toJSON()
            const data = {
                browser: browser,
                user_agent: user_agent,
                endpoint: subscription.endpoint,
                auth: subscription.keys.auth,
                p256dh: subscription.keys.p256dh
                // status_type: statusType,
                // group: subBtn.dataset.group
            };
            return new Promise((resolve, reject) => {
                fetchWrapper.post(`${baseUrl}/webpush/subscription-information/`, data).then(() => {
                    resolve('ok')
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        },
        async subscribeNotify(reg) {
            function urlB64ToUint8Array(base64String) {
                const padding = '='.repeat((4 - base64String.length % 4) % 4);
                const base64 = (base64String + padding)
                    .replace(/\-/g, '+')
                    .replace(/_/g, '/');

                const rawData = window.atob(base64);
                const outputArray = new Uint8Array(rawData.length);

                for (var i = 0; i < rawData.length; ++i) {
                    outputArray[i] = rawData.charCodeAt(i);
                }
                return outputArray;
            }
            // Get the Subscription or register one
            const that = this;
            reg.pushManager.getSubscription().then(
                (subscription) => {
                    var applicationServerKey, options;
                    // Check if Subscription is available
                    // if (subscription) {
                    //     return subscription;
                    // }
                    applicationServerKey = that.vapidPubKey;
                    options = {
                        userVisibleOnly: true
                    };
                    if (applicationServerKey) {
                        options.applicationServerKey = urlB64ToUint8Array(applicationServerKey)
                    }
                    // If not, register one
                    reg.pushManager.subscribe(options).then((subscription) => {
                        that.sendPushInfo(subscription)
                    })
                }
            );
        }
    }
});