<script setup>
import { RouterView } from 'vue-router'
import { useAuthStore } from '@/stores/auth_store'
import { useWebApp } from 'vue-tg'
import { useWebAppViewport } from 'vue-tg'

const auth = useAuthStore()
const webApp = useWebApp()

if (webApp.initData) {
  auth.isTelegramApp = true
  const webAppViewport = useWebAppViewport()
  webAppViewport.expand()
}

auth.testToken()
</script>

<template>
  <RouterView />
</template>
