export default {
    menuAside: {
        menu: 'Меню',
        dashboard: 'Дашбоард',
        rollCalls: 'Переклички',
        rollCallsActive: 'Активные',
        rollCallsHistory: 'История',
        clients: 'Клиенты',
        clientsList: 'Список',
        credits: 'Кредиты',
        accounts: 'Счета',
        products: 'Товары',
        list: 'Список',
        categories: 'Категории',
        manufacture: 'Производство',
        incomes: 'Приемка',
        expenditures: 'Продажи',
        transfers: 'Переводы',
        currencyAccounts: 'Счета',
        warehouse: 'Склад',
        warehouseIncomes: 'Приходы',
        warehouseExpenditures: 'Расходы',
        logout: 'Выход',
    },
    menuNavBar: {
        myprofile: 'Мой профиль',
        logout: 'Выход',
        lightdark: 'Светлая/Тёмная',
    },
    somethingWrong: 'Что-то пошло не так',
    paginator: 'Страница {currentPageHuman} из {numPages}',
    cancel: 'Отмена',
    apply: 'Применить',
    reset: 'Сбросить',
    selectoption: 'Выберите один',
    selectLabel: 'Нажмите для выбора',
    deselectLabel: 'Нажмите для удаления',
    selectedLabel: 'Выбрано',
    "Nothing's here…": "Здесь пока ничего нет…",
    'List is empty': 'Список пуст',
    'No elements found': 'Ничего не найдено. Попробуйте изменить запрос',
    profile: {
        title: 'Профиль',
        avatar: 'Аватар',
        avatarHelp: 'Максимально 500kb',
        upload: 'Загрузка',
        firstname: 'Фамилия',
        firstnameHelp: 'Обязательное поле. Ваша фамилия',
        lastname: 'Имя',
        lastnameHelp: 'Обязательное поле. Ваше имя',
        phone: 'Телефон',
        phoneHelp: 'Ваш телефон',
        submitLabel: 'Отправить',
        timezone: 'Выбранная временная зона',
        timezoneHelp: 'Выберите Вашу временную зону',
        language: 'Выбранный язык',
        languageHelp: 'Выберите Ваш язык',
        currentPassword: 'Текущий пароль',
        currentPasswordHelp: 'Обязательное поле. Ваш текущий пароль',
        newPassword: 'Новый пароль',
        newPasswordHelp: 'Обязательное поле. Новый пароль',
        confirmNewPassword: 'Подтвердите пароль',
        confirmNewPasswordHelp: 'Обязательное поле. Новый пароль ещё раз',
        usercardHello: 'Здравствуй'
    },
    products: {
        newproduct: 'Новый продукт',
        add: 'Добавить',
        category: 'Категория',
        product: 'Продукт',
        categoryHelp: 'Выберите категорию',
        name: 'Наименование',
        nameHelp: 'Введите наименование продукта',
        productlist: 'Список продуктов',
        addcategory: 'Новая категория продуктов',
        categoryNameHelp: 'Введите наименование категории',
        categoryList: 'Список категорий продуктов',
        parentcategory: 'Родительская категория',
        price: 'Цена',
        apply: 'Применить',
        "There are not enough products in stock": "Товара на складе не достаточно"
    },
    manufacture: {
        carAmount: 'Количество машин',
        user: 'Пользователь',
        timestamp: 'Дата/время',
        viewItems: 'Просмотр',
        itemName: 'Наименование',
        amount: 'Количество',
        newCarIncome: 'Новый прием машины',
        add: 'Добавить',
        carConfiguration: 'Конфигурация машины',
        carConfigurationHelp: 'Выберите конфигурацию машины',
        amountHelp: 'Введите количество машин',
        itemsInIncomeTitle: 'Позиции в приходе',
        incomes: 'Приходы',
        addIncome: 'Добавить',
        expenditures: 'Продажи',
        expenditure: 'Продажа',
    },
    clients: {
        newClient: 'Новый клиент',
        add: 'Добавить',
        firstName: 'Фамилия',
        firstNameHelp: 'Введите фамилию',
        lastName: 'Имя',
        lastNameHelp: 'Введите имя',
        phone: 'Телефон',
        phoneHelp: 'Введите телефон',
        clientsTitle: 'Клиенты',
        addClient: 'Добавить',
        fullname: 'Имя',
        company: 'Компания',
        spent: 'Потрачено',
        newClientCurrencyAccount: 'Новый счет клиента',
        client: 'Клиент',
        clientHelp: 'Выберите клиента',
        currency: 'Валюта',
        currencyHelp: 'Выберите валюту',
        clientsCreditsTitle: 'Кредиты клиентов',
        duplicateCCAError: 'Счет клиента с выбранной валютой уже существует',
        newClientCreditLine: 'Новая кредитная линия клиента',
        creditLineAmount: 'Сумма',
        creditLineAmountHelp: 'Введите сумму кредитной линии',
        clientsCreditLinesTitle: 'Кредитные линии клиентов',
        addLine: 'Добавить',
        open: 'Открыта',
        isClose: 'Закрыта',
        isOpen: 'Открыта',
        clientName: 'Имя клиента',
        credits: 'Кредиты',
        credit: 'Кредит',
        debt: 'Задолженность',
        loans: 'Погашено',
        clientsCurrencyAccounts: 'Счета пользователей',
        addAccount: 'Добавить',
        balanceIsNotEnough: 'На баллансе недостаточно средств',
        newMoneyTransfer: 'Новый перевод',
        newMoneyIncome: 'Новое пополнение',
        newMoneyExpenditure: 'Новая трата',
        debitAmount: 'Сумма списания',
        debitAmountHelp: 'Введите сумму списания',
        receiptCurrencyAccount: 'Счет зачисления',
        receiptCurrencyAccountHelp: 'Введите счет зачисления',
        balance: 'Баланс',
        amount: 'Сумма',
        amountHelp: 'Введите сумму',
        receiptAmount: 'Сумма зачисления',
        receiptAmountHelp: 'Введите сумму зачисления',
        clientCurrencyAccountTitle: 'Счет клиента',
        moneyTransfers: 'Переводы',
        moneyIncomes: 'Поступления',
        moneyExpenditures: 'Списания',
        receipt: 'Пополнение',
        debit: 'Списание',
        timestamp: 'Дата/время',
        user: 'Пользователь',
        debitor: 'Отправитель',
        recipient: 'Получатель',
        opponent: 'Отправитель/Получатель',
        newCreditRepayment: 'Новое погашение',
        currencyAccount: 'Счет',
        "Please choose currency account in client currency": "Выберите счет зачисления в валюте клиента",
        "Please enter repayment amount": "Введите сумму погашения",
        "Exchange rate": "Курс обмена",
        exchangeRateHelp: "Введите курс обмена. Сумма погашения",
        "Client credit line": "Кредитная линия клиента",
        "in": "в",
        addRepayment: 'Погасить',
        "Credit line is closed": "Кредитная линия закрыта",
        "Credit line amount is not enogh": "Лимит кредитной линии не достаточен",
        "ClientDoesnotExists": "Клиент не существует"
    },
    cart: {
        addToCart: "Добавить в корзину",
        price: "Цена",
        amount: "Количество",
        total: "Сумма",
        ok: "Ок",
        removeFromCart: "Удалить",
        add: 'Добавить',
        view: 'Просмотр',
        cartTitle: 'Корзина',
        name: 'Наименование',
        clearCart: 'Очистить',
        clearCartTitle: 'Очистить козину',
        clearCartBody: 'Вы действительно хотите очистить корзину?',
        pay: 'Оплатить',
        kassa: 'Наличные',
        credit: 'Кредит',
        prepayment: 'Предоплата',
        paymentType: 'Тип оплаты',
        "ProductsDoesNotExists": "Товар не существует"
    },
    currencyAccounts: {
        account: 'Счёт',
        "CurrencyAccounDoesnotExists": "Счёт не существует",
        title: 'Счета',
        name: 'Наименование',
        currency: 'Валюта',
        balance: 'Баланс',
        view: 'Просмотр',
        newCurrencyAccount: 'Новый счёт',
        add: 'Добавить',
        currencyHelp: 'Выберите валюту',
        moneyTransfers: 'Переводы',
        moneyIncomes: 'Поступления',
        moneyExpenditures: 'Списания',
        receipt: 'Пополнение',
        debit: 'Списание',
        timestamp: 'Дата/время',
        user: 'Пользователь',
        debitor: 'Отправитель',
        recipient: 'Получатель',
        opponent: 'Отправитель/Получатель',
        addAccount: 'Добавить',
        balanceIsNotEnough: 'На баллансе недостаточно средств',
        newMoneyTransfer: 'Новый перевод',
        newMoneyIncome: 'Новое пополнение',
        newMoneyExpenditure: 'Новая трата',
        debitAmount: 'Сумма списания',
        debitAmountHelp: 'Введите сумму списания',
        receiptCurrencyAccount: 'Счет зачисления',
        receiptCurrencyAccountHelp: 'Введите счет зачисления',
        amount: 'Сумма',
        amountHelp: 'Введите сумму',
        receiptAmount: 'Сумма зачисления',
        receiptAmountHelp: 'Введите сумму зачисления',
        transfer: "Transfer",
        income: "Income",
        expenditure: "Expenditure",
        transfers: 'Переводы',
        incomes: 'Поступления',
        expenditures: 'Расходы',
    },
    warehouse: {
        productlist: 'Список товаров на складе',
        productIncomelist: 'Список приходов',
        productExpenditurelist: 'Список расходов',
        receivingUser: 'Получатель',
        submittingUser: 'Отправитель'
    },
    rollCalls: {
        EnterMessage: 'Введите сообщение',
        ok: "Ok",
        start: "Начало",
        expires: "Длительность",
        tooFarFromTheOfficeError: 'Вы слишком далеко от офиса',
        question: 'Вопрос',
        answerState: 'Ответ',
        timestamp: 'Дата/время',
        history: 'История',
        locationRequired: 'Требуется локация'
    }
}