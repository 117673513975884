import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import * as Sentry from "@sentry/vue";
import VueTelegram from 'vue-tg'
import App from './App.vue'
import router from './router'
// import { useMainStore } from '@/stores/main.js'
import 'vue-multiselect/dist/vue-multiselect.css';
import './css/main.css'

// Init Pinia
const pinia = createPinia()

// Init i18n
import i18n from './i18n'

// Create Vue app
const app = createApp(App);
// if (`${import.meta.env.VITE_ENVIRONMENT}` == 'production') {
//   Sentry.init({
//     app,
//     dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       }),
//       new Sentry.Replay(),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,

//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     // tracePropagationTargets: ["localhost", `${import.meta.env.VITE_BASE_URL}`],

//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

app.use(i18n)
app.use(VueTelegram)
app.use(router).use(pinia)
app.mount('#app')

// Init main store
// const mainStore = useMainStore(pinia)

// Fetch sample data
// mainStore.fetchSampleClients()
// mainStore.fetchSampleHistory()

// Dark mode
// Uncomment, if you'd like to restore persisted darkMode setting, or use `prefers-color-scheme: dark`. Make sure to uncomment localStorage block in src/stores/darkMode.js
import { useDarkModeStore } from './stores/darkMode'

const darkModeStore = useDarkModeStore(pinia)

if (
  (!localStorage['darkMode'] && window.matchMedia('(prefers-color-scheme: dark)').matches) ||
  localStorage['darkMode'] === '1'
) {
  darkModeStore.set(true)
} else {
  darkModeStore.set(false)
}

// Default title tag
const defaultDocumentTitle = 'Rezultatplus'

// Set document title from route meta
router.afterEach((to) => {
  document.title = to.meta?.title
    ? `${to.meta.title} — ${defaultDocumentTitle}`
    : defaultDocumentTitle
})
