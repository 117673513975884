import { computed } from 'vue'

import i18n from './i18n'

const { t } = i18n.global;

import {
  mdiMonitor,
  mdiAccountGroupOutline,
  mdiAccountSearchOutline,
  mdiBellAlertOutline,
  mdiBellRing,
  mdiAccountCreditCardOutline,
  mdiHistory,
  mdiCashMultiple,
  mdiTruckCargoContainer,
  mdiDomain,
  mdiCartVariant,
  mdiStore,
  mdiShopping,
  mdiStoreSettings,
  mdiWarehouse,
  mdiFormatListBulleted,
  mdiChevronTripleDown,
  mdiChevronTripleUp,
  mdiCurrencyUsd,
  mdiSwapHorizontalBold
} from '@mdi/js'

export default computed(() => [
  {
    to: '/dashboard',
    icon: mdiMonitor,
    label: t('menuAside.dashboard')
  },
  {
    label: t('menuAside.rollCalls'),
    icon: mdiBellRing,
    menu: [
      {
        to: '/roll-calls/active',
        label: t('menuAside.rollCallsActive'),
        icon: mdiBellAlertOutline
      },
      {
        to: '/roll-calls/history',
        label: t('menuAside.rollCallsHistory'),
        icon: mdiHistory
      },
    ]
  },

  //   label: t('menuAside.clients'),
  //   icon: mdiAccountGroupOutline,
  //   menu: [
  //     {
  //       to: '/clients',
  //       label: t('menuAside.clientsList'),
  //       icon: mdiAccountSearchOutline
  //     },
  //     {
  //       to: '/clients/credit-lines',
  //       label: t('menuAside.credits'),
  //       icon: mdiAccountCreditCardOutline
  //     },
  //     {
  //       to: '/clients/currency-accounts',
  //       label: t('menuAside.accounts'),
  //       icon: mdiCashMultiple
  //     },
  //   ]
  // },
  // {
  //   label: t('menuAside.products'),
  //   icon: mdiStore,
  //   menu: [
  //     {
  //       to: '/products',
  //       label: t('menuAside.list'),
  //       icon: mdiShopping
  //     },
  //     {
  //       to: '/products/categories',
  //       label: t('menuAside.categories'),
  //       icon: mdiStoreSettings
  //     },
  //   ]
  // },
  // {
  //   label: t('menuAside.manufacture'),
  //   icon: mdiDomain,
  //   menu: [
  //     {
  //       to: '/manufacture/incomes',
  //       label: t('menuAside.incomes'),
  //       icon: mdiTruckCargoContainer
  //     },
  //     {
  //       to: '/manufacture/expenditures',
  //       label: t('menuAside.expenditures'),
  //       icon: mdiCartVariant
  //     },
  //   ]
  // },
  // {
  //   label: t('menuAside.currencyAccounts'),
  //   icon: mdiCurrencyUsd,
  //   menu: [
  //     {
  //       to: '/currency-accounts',
  //       label: t('menuAside.list'),
  //       icon: mdiFormatListBulleted
  //     },
  //     {
  //       to: '/currency-accounts/incomes',
  //       label: t('currencyAccounts.incomes'),
  //       icon: mdiChevronTripleDown
  //     },
  //     {
  //       to: '/currency-accounts/expenditures',
  //       label: t('currencyAccounts.expenditures'),
  //       icon: mdiChevronTripleUp
  //     },
  //     {
  //       to: '/currency-accounts/transfers',
  //       label: t('menuAside.transfers'),
  //       icon: mdiSwapHorizontalBold
  //     },
  //   ]
  // },
  // {
  //   label: t('menuAside.warehouse'),
  //   icon: mdiWarehouse,
  //   menu: [
  //     {
  //       to: '/warehouse/items-in-warehouse',
  //       label: t('menuAside.list'),
  //       icon: mdiFormatListBulleted
  //     },
  //     {
  //       to: '/warehouse/incomes',
  //       label: t('menuAside.warehouseIncomes'),
  //       icon: mdiChevronTripleDown
  //     },
  //     {
  //       to: '/warehouse/expenditures',
  //       label: t('menuAside.warehouseExpenditures'),
  //       icon: mdiChevronTripleUp
  //     },
  //   ]
  // },
]
)